<template>
  <div class="check">
    <Header currentPage="check"></Header>
    <section class="check__content">
      <div v-if="getCurrentQuestion.id === 0 && !getResult" class="begin">
        <h1 class="check__title">Оцінка необхідності імплантації кардіовертера-дефібрилятора (ІКД)</h1>
        <div class="check__description">Цей алгоритм розрахований на пацієнтів з імовірним високим ризиком раптової серцевої смерті (РСС).</div>
      </div>
      <div v-if="getResult" class="check__result">
        <h1 class="check__result--title">Результат оцінки необхідності ІКД вашого пацієнта</h1>
      </div>
      <div  v-if="getCurrentQuestion.id !== 0 && !getResult">
        <h1 class="check__title">Оцінка необхідності ІКД</h1>
      </div>
      <Question v-if="!getResult" />
      <Result v-if="getResult" />
    </section>
    <transition name="fade">
      <img
        v-if="!openInfo"
        src="@/assets/img/btn_patient.svg"
        alt=""
        class="check__icon"
        @click="handleInfo()"
      >
      <UserData
        v-if="openInfo"
        currentPage="check"
        :userData="getAnswers"
        title="Дані пацієнта"
        @handleInfo="handleInfo()"
      />
    </transition>
    <footer>
      <transition name="fade">
        <img
          v-if="!openInfo"
          src="@/assets/img/btn_patient.svg"
          alt=""
          class="footer__icon"
          @click="handleInfo()"
        >
      </transition>
<!--      © 2022 «Medtronic»-->
    </footer>
  </div>
</template>

<script>
import Question from '@/components/Check/Question'
import UserData from '@/components/UserData'
import Result from '@/components/Check/Result'
import Header from '../components/Header.vue'

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Check',
  components: {
    Header,
    Question,
    UserData,
    Result
  },
  data: () => ({
    stage: 0,
    questions: [],
    titles: [],
    openInfo: false
  }),
  computed: {
    ...mapGetters([
      'getStage',
      'getQuestions',
      'getResult',
      'getCurrentQuestion',
      'getPatientData',
      'getAnswers'
    ])
  },
  methods: {
    ...mapMutations([
      'setCurrentQuestion'
    ]),
    handleInfo () {
      this.openInfo = !this.openInfo
    }
  },
  created () {
    const question = this.getQuestions[this.getStage]

    this.setCurrentQuestion(question)
  }
}
</script>

<style lang="scss" scoped>
$fontR: "Montserrat-Regular";
$fontM: "Montserrat-Medium";
$fontB: "Montserrat-Bold";
$fontSB: "Montserrat-SemiBold";

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

  .check {
    width: 100%;
    padding: 0 5%;
    padding-top: 120px;
    text-align: left;
    display: grid;
    grid-template-columns: 65% 30%;
    grid-column-gap: 5%;
    position: relative;

    &__content {
      width: 100%;
    }
    &__icon {
      position: absolute;
      top: 160px;
      cursor: pointer;
      right: 99px;
    }

    &__title {
      font-family: $fontM;
      font-weight: 500;
      font-size: 32px;
      line-height: 39px;
      color: #3C3C3C;
      margin-bottom: 42px;
      width: 80%;
    }

    &__description {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 49px;
      width: 90%;
    }

    .begin {
      .check__title {
        color: #FB35A2;
        margin-bottom: 10px;
      }
    }

    footer {
      display: none;
      background-color: #fff;
      z-index: 1;
    }

    &__result {
      &--title {
        font-family: $fontM;
        font-weight: 500;
        font-size: 32px;
        line-height: 39px;
        width: 70%;
        margin-bottom: 52px;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .check {
      &__icon {
        right: 66px;
      }
    }
  }
  @media screen and (max-width: 1020px) {
    .check {
      grid-template-columns: 1fr;

      &__description {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 30px;
      }

      &__icon {
        display: none;
      }

      &__title {
        width: 100%;
      }

      &__result {
        &--title {
          font-size: 28px;
          line-height: 36px;
          width: 80%;
          margin-bottom: 36px;
        }
      }

      .begin {
        .check__title {
          font-size: 20px;
          line-height: 24px;
          width: 100%;
        }
      }

      footer {
        display: block;
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        padding: 11px 0;
        text-align: center;
        //border-top: 1px solid rgba($color: #3C3C3C, $alpha: 0.5);
        z-index: 2;
      }
      .footer__icon {
        display: block;
        width: 50px;
        height: auto;
        position: absolute;
        bottom: 10px;
        right: 20px;
        outline: none;
        overflow: hidden;
        border-radius: 50%;
      }
    }
  }
  @media screen and (max-width: 540px) {
    .check {
      padding-top: 105px;
      &__title {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 36px;
      }
      &__result {
        &--title {
          font-family: $fontM;
          font-size: 20px;
          line-height: 24px;
          width: 100%;
          margin-bottom: 24px;
        }
      }
    }
  }

</style>

<template>
  <section class="question" :class="{ first: getCurrentQuestion.id === 0 }">
    <h2 class="question__title">{{ getCurrentQuestion.title }}</h2>
    <h3 v-if="getCurrentQuestion.subtitle" class="question__subtitle">{{ getCurrentQuestion.subtitle }}</h3>
    <p v-if="getCurrentQuestion.id === 12 || getCurrentQuestion.id === 11" class="question__descr">Можна обрати декілька
      варіантів</p>
    <ul v-if="getCurrentQuestion.id === 0" class="question__answers-list">
      <li :onclick="`gtag('event', 'click', {'event_category': 'У вашого пацієнта: ${answer.title}'})`"
          v-for="answer of getCurrentQuestion.answers"
          :key="answer.id"
          @click="handleAnswer(answer, getCurrentQuestion.type)"
          v-on:click="handleNext(getStage)"
          class="question__answers-item answer"
      >
        <p class="answer__title">
          {{ answer.title }}
        </p>
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="15" cy="15" r="15" fill="white"/>
          <path d="M13 8L20 15L13 22" stroke="#285EFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="answer__text">
          {{ answer.text }}
        </p>
      </li>
    </ul>
    <ul v-else class="question__answers-list">
      <li
        v-for="answer of getCurrentQuestion.answers"
        :key="answer.id"
        @click="handleAnswer(answer, getCurrentQuestion.type)"
        class="question__answers-item answer"
        :class="{moreContent: getCurrentQuestion.id === 1 || getCurrentQuestion.id === 12 || getCurrentQuestion.id === 11, aLot: getCurrentQuestion.id === 10}"
      >
        <input
          v-if="getCurrentQuestion.id === 12 || getCurrentQuestion.id === 11"
          type="checkbox"
          :id="answer.id"
          :value="currentAnswer"
          :name="getCurrentQuestion.id"
          class="answer__input"
        >
        <input
          v-else
          type="radio"
          :id="answer.id"
          :value="currentAnswer"
          :name="getCurrentQuestion.id"
          class="answer__input"
          :checked="getAnswers[getStage] && getAnswers[getStage].id === answer.id"
        >
        <label :for="currentAnswer" class="answer__label">
          <div class="decor-container">
            <div class="decor-round"
                 :class="{square: getCurrentQuestion.id === 12 || getCurrentQuestion.id === 11}"></div>
          </div>
          <div class="answer__content">
            <p class="answer__title">{{ answer.title }}</p>
            <p class="answer__text">{{ answer.text }}</p>
          </div>
        </label>
      </li>
    </ul>

    <div class="question__controls">
      <button
        @click="handlePrev"
        :disabled="!getPrevQuestions.length"
        class="question__btn prev"
      >
        Назад
      </button>

      <button
        @click="handleNext(getStage)"
        :disabled="!getAnswers[getStage] || this.disabledBtnNext"
        class="question__btn next"
        :class="{disabled: !getAnswers[getStage] || this.disabledBtnNext}"
      >
        <div class="question__btn--text">
          Далі
        </div>
        <div class="after question__btn--decor">
          <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L8 8L1 15" stroke="#FB35A2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </button>
    </div>

    <div
      class="question__abbrev-container"
      :class="{together: getCurrentQuestion.id === 11}"
      v-for="(link, i) in getCurrentQuestion.links"
      :key="i"
      v-html="link"
    >
    </div>

  </section>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'

export default {
  name: 'Question',
  data() {
    return {
      currentAnswer: [],
      disabledBtnNext: false
    }
  },
  computed: {
    ...mapGetters([
      'getStage',
      'getQuestions',
      'getCurrentQuestion',
      'getPrevQuestions',
      'getAnswers',
      'getPatientData'
    ])
  },
  methods: {
    ...mapMutations([
      'setStage',
      'setCurrentQuestion',
      'setAnswers',
      'setPatientData'
    ]),
    ...mapActions([
      'goToQuestion',
      'goToResult'
    ]),
    handleAnswer(answer, type) {
      let currentStageAnswers = this.getAnswers[this.getStage]
      const answerInputs = document.querySelectorAll('.answer__input')
      const nextBtn = document.querySelector('.next')

      switch (type) {
        case 'SINGLE':
          this.setAnswers({
            ...this.getAnswers,
            [this.getStage]: answer
          })
          if (answer.abbrev) {
            this.setPatientData({
              ...this.getPatientData,
              [this.getStage]: answer.abbrev
            })
            // answer.abbrev.forEach(item => this.setPatientData(item))
          } else {
            this.setPatientData({
              ...this.getPatientData,
              [this.getStage]: null
            })
          }
          break
        case 'MULTIPLE':
          if (!currentStageAnswers) {
            if (answer.id === '12-0') {
              this.disabledBtnNext = true
              answerInputs.forEach((element) => {
                if ((element.id === '12-1' && !element.getAttribute('disabled')) || (element.id === '12-5' && !element.getAttribute('disabled'))) {
                  element.setAttribute('disabled', true)
                  this.disabledBtnNext = true
                } else if (element.id === '12-1' || element.id === '12-5') {
                  element.removeAttribute('disabled')
                  this.disabledBtnNext = false
                }
              })
            }

            if (answer.id === '12-2') {
              this.disabledBtnNext = true
              nextBtn.disabled = true

              answerInputs.forEach((element) => {
                if (element.id === '12-5' && !element.disabled) {
                  element.disabled = true
                }
              })
            }

            if (answer.id === '12-1') {
              answerInputs.forEach((element) => {
                if ((element.id === '12-0' && !element.getAttribute('disabled')) || (element.id === '12-5' && !element.getAttribute('disabled'))) {
                  element.setAttribute('disabled', true)
                  this.disabledBtnNext = true
                } else if (element.id === '12-0' || element.id === '12-5') {
                  element.removeAttribute('disabled')
                  this.disabledBtnNext = false
                }
              })
            }

            if (answer.id === '12-3') {
              answerInputs.forEach((element) => {
                if ((element.id === '12-5' && !element.disabled)) {
                  element.disabled = true
                } else if ((element.id === '12-5') && element.disabled) {
                  element.disabled = false
                }
              })
            }
            if (answer.id === '12-4') {
              answerInputs.forEach((element) => {
                if ((element.id === '12-5' && !element.disabled)) {
                  element.disabled = true
                } else if ((element.id === '12-5') && element.disabled) {
                  element.disabled = false
                }
              })
            }

            if (answer.id === '12-5') {
              answerInputs.forEach((element) => {
                if ((element.id === '12-0' && !element.getAttribute('disabled')) ||
                  (element.id === '12-1' && !element.getAttribute('disabled')) ||
                  (element.id === '12-2' && !element.getAttribute('disabled')) ||
                  (element.id === '12-3' && !element.getAttribute('disabled')) ||
                  (element.id === '12-4' && !element.getAttribute('disabled'))) {
                  element.setAttribute('disabled', true)
                  this.disabledBtnNext = false
                } else if (element.id !== '12-5') {
                  element.removeAttribute('disabled')
                  this.disabledBtnNext = false
                }
              })
              this.setPatientData({
                ...this.getPatientData,
                [this.getStage]: answer.abbrev
              })
            } else {
              answerInputs.forEach(element => {
                if ((element.id === '12-0' && element.getAttribute('disabled')) ||
                  (element.id === '12-1' && element.getAttribute('disabled')) ||
                  (element.id === '12-2' && element.getAttribute('disabled')) ||
                  (element.id === '12-3' && element.getAttribute('disabled')) ||
                  (element.id === '12-4' && element.getAttribute('disabled'))
                ) {
                  element.setAttribute('disabled', false)
                }
              })
            }

            if (answer.id === '11-6') {
              answerInputs.forEach((element) => {
                if ((element.id === '11-0' && !element.getAttribute('disabled')) ||
                  (element.id === '11-1' && !element.getAttribute('disabled')) ||
                  (element.id === '11-2' && !element.getAttribute('disabled')) ||
                  (element.id === '11-3' && !element.getAttribute('disabled')) ||
                  (element.id === '11-4' && !element.getAttribute('disabled')) ||
                  (element.id === '11-5' && !element.getAttribute('disabled'))
                ) {
                  element.disabled = true
                  this.disabledBtnNext = false
                } else if (element.id !== '11-6') {
                  element.disabled = false
                }
              })
            } else {
              answerInputs.forEach((element) => {
                if (element.id === '11-6' && !element.getAttribute('disabled')) {
                  element.disabled = true
                }
              })
            }

            this.setAnswers({
              ...this.getAnswers,
              [this.getStage]: [answer]
            })
            if (answer.abbrev && !this.getPatientData[this.getStage]) {
              this.setPatientData({
                ...this.getPatientData,
                [this.getStage]: answer.abbrev
              })
            }
          } else if (currentStageAnswers) {
            const isAlreadyChecked = currentStageAnswers.some(item => item.id === answer.id)
            if (!currentStageAnswers.some(element => element.id === '11-6') && this.getCurrentQuestion.id === 11) {
              answerInputs.forEach((el) => {
                el.disabled = false
              })
              this.setPatientData({
                ...this.getPatientData,
                [this.getStage]: answer.abbrev
              })
            }
            if (currentStageAnswers.some((element) => element.id === '11-6')) {
              answerInputs.forEach((el) => {
                el.disabled = false
              })
              this.setPatientData({
                ...this.getPatientData,
                [this.getStage]: null
              })
            }

            if (!this.getAnswers[this.getStage]) {
              this.disabledBtnNext = true
            }

            if (isAlreadyChecked) {
              if (currentStageAnswers.length) {
                if (currentStageAnswers.some(item => item.id === '12-0')) {
                  answerInputs.forEach((element) => {
                    if ((element.id === '12-1') || (element.id === '12-5')) {
                      element.removeAttribute('disabled')
                      this.disabledBtnNext = false
                    }
                  })
                  this.disabledBtnNext = true
                }
                if (currentStageAnswers.some(item => item.id === '12-1')) {
                  answerInputs.forEach((element) => {
                    if ((element.id === '12-0') || (element.id === '12-5')) {
                      element.removeAttribute('disabled')
                      this.disabledBtnNext = false
                    }
                  })
                  if (currentStageAnswers.length <= 1) {
                    this.disabledBtnNext = true
                  }
                }
                if (currentStageAnswers.some(item => item.id === '12-3')) {
                  this.disabledBtnNext = false
                  answerInputs.forEach((element) => {
                    if (element.id === '12-5' && !element.disabled) {
                      element.disabled = true
                    } else if (element.id === '12-5' && element.disabled) {
                      element.disabled = false
                    }
                  })
                }
                if (currentStageAnswers.some(item => item.id === '12-2')) {
                  answerInputs.forEach((element) => {
                    if ((element.id === '12-4') || element.id === '12-3') {
                      element.removeAttribute('disabled')
                      this.disabledBtnNext = true
                    }
                    if (element.id === '12-5' && !element.disabled) {
                      element.disabled = true
                    } else if (element.id === '12-5' && element.disabled) {
                      element.disabled = false
                    }
                  })
                }
                if (currentStageAnswers.some(item => item.id === '12-4')) {
                  this.disabledBtnNext = false
                  answerInputs.forEach((element) => {
                    if (element.id === '12-5' && !element.disabled) {
                      element.disabled = true
                    } else if (element.id === '12-5' && element.disabled) {
                      element.disabled = false
                    }
                  })
                }
                if (currentStageAnswers.some(item => item.id === '12-5')) {
                  // this.disabledBtnNext = false
                  answerInputs.forEach((element) => {
                    if ((element.id === '12-0' && !element.disabled) ||
                      (element.id === '12-1' && !element.disabled) ||
                      (element.id === '12-2' && !element.disabled) ||
                      (element.id === '12-3' && !element.disabled) ||
                      (element.id === '12-4' && !element.disabled)) {
                      element.setAttribute('disabled', true)
                      this.disabledBtnNext = false
                    } else {
                      element.disabled = false
                      this.disabledBtnNext = true
                    }
                  })
                  this.setPatientData({
                    ...this.getPatientData,
                    [this.getStage]: null
                  })
                } else {
                  answerInputs.forEach(element => {
                    if ((element.id === '12-0' && element.getAttribute('disabled')) ||
                      (element.id === '12-1' && element.getAttribute('disabled')) ||
                      (element.id === '12-2' && element.getAttribute('disabled')) ||
                      (element.id === '12-3' && element.getAttribute('disabled')) ||
                      (element.id === '12-4' && element.getAttribute('disabled'))) {
                      element.setAttribute('disabled', false)
                    }
                  })
                }
                if (!this.getAnswers[this.getStage]) {
                  answerInputs.forEach((element) => {
                    element.disabled = false
                  })
                }
                currentStageAnswers = currentStageAnswers.filter(item => item.id !== answer.id)
              } else {
                currentStageAnswers = null
                answerInputs.forEach(el => {
                  el.removeAttribute('disabled')
                })
              }

              if (currentStageAnswers.some(item => item.id === '11-6') && this.getPatientData[this.getStage] && (this.getPatientData[this.getStage][0] === currentStageAnswers.find(item => item.id === '11-6').abbrev[0])) {
                this.setPatientData({
                  ...this.getPatientData,
                  [this.getStage]: null
                })
              }
            } else {
              currentStageAnswers.push(answer)

              if (currentStageAnswers.some(item => item.id === '12-0')) {
                answerInputs.forEach((element) => {
                  if ((element.id === '12-1' && !element.getAttribute('disabled')) || (element.id === '12-5' && !element.getAttribute('disabled'))) {
                    element.setAttribute('disabled', true)
                  } else if (element.id === '12-1' || element.id === '12-5') {
                    element.removeAttribute('disabled')
                  } else if (element.id === '12-4') {
                    this.disabledBtnNext = false
                  } else {
                    this.disabledBtnNext = true
                  }
                })
              }

              if (currentStageAnswers.some(item => item.id === '12-1')) {
                answerInputs.forEach((element) => {
                  if ((element.id === '12-0' && !element.getAttribute('disabled')) || (element.id === '12-5' && !element.getAttribute('disabled'))) {
                    element.setAttribute('disabled', true)
                  } else if (element.id === '12-0' || element.id === '12-5') {
                    element.removeAttribute('disabled')
                  } else if (element.id === '12-4') {
                    this.disabledBtnNext = false
                  }
                })

                if (currentStageAnswers.length <= 1) {
                  this.disabledBtnNext = true
                }
              }
              if (currentStageAnswers.some(item => item.id === '12-2')) {

                answerInputs.forEach((element) => {
                  if (((element.id === '12-4') && currentStageAnswers.some(it => it.id === '12-0'))) {
                    element.removeAttribute('disabled')
                    this.disabledBtnNext = true
                  } else if (element.id === '12-3' && currentStageAnswers.some(it => it.id === '12-0')) {
                    element.removeAttribute('disabled')
                    this.disabledBtnNext = true
                  }
                })

                if (currentStageAnswers.some(item => item.id === '12-4') && currentStageAnswers.length === 2) {
                  this.disabledBtnNext = true
                  return
                }
              }

              if (currentStageAnswers.some(item => item.id === '12-0')) {
                this.disabledBtnNext = true
                answerInputs.forEach((element) => {
                  if ((element.id === '12-2')) {
                    element.removeAttribute('disabled')
                    this.disabledBtnNext = false
                  }
                })
              }
              if (currentStageAnswers.some(item => item.id === '12-2')) {
                if (currentStageAnswers.some(item => item.id === '12-0')) {
                  answerInputs.forEach((element) => {
                    if (element.id === '12-1') {
                      element.disabled = true
                    }
                  })
                }
                if (currentStageAnswers.some(item => item.id === '12-1')) {
                  answerInputs.forEach((element) => {
                    if (element.id === '12-0') {
                      element.disabled = true
                    }
                  })
                  return
                }
                answerInputs.forEach((element) => {
                  if ((element.id === '12-0')) {
                    element.removeAttribute('disabled')
                    this.disabledBtnNext = false
                  } else if ((element.id === '12-4')) {
                    element.removeAttribute('disabled')
                  } else if (element.id === '12-5') {
                    element.disabled = true
                  }
                })
              }
              if (currentStageAnswers.some(item => item.id === '12-3')) {
                answerInputs.forEach((element) => {
                  if (element.id === '12-5') {
                    element.disabled = true
                  }
                })
                if (currentStageAnswers.some(item => item.id === '12-0')) {
                  answerInputs.forEach((element) => {
                    if (element.id === '12-1') {
                      element.disabled = true
                    }
                  })
                }
                if (currentStageAnswers.some(item => item.id === '12-1')) {
                  answerInputs.forEach((element) => {
                    if (element.id === '12-0') {
                      element.disabled = true
                    }
                  })
                }
                if (currentStageAnswers.length === 1) {
                  this.disabledBtnNext = false
                  return
                }
                if (currentStageAnswers.some(item => item.id === '12-2') && currentStageAnswers.length === 2) {
                  this.disabledBtnNext = true
                }
                return
              }
              if ((currentStageAnswers.some(item => item.id === '12-2') && currentStageAnswers.some(item => item.id === '12-3')) ||
                (currentStageAnswers.some(item => item.id === '12-2') && currentStageAnswers.some(item => item.id === '12-4'))) {
                this.disabledBtnNext = true
              }
              if (currentStageAnswers.some(item => item.id === '12-4') && currentStageAnswers.length > 1) {
                this.disabledBtnNext = false
                answerInputs.forEach((element) => {
                  if ((element.id === '12-5' && !element.disabled)) {
                    element.disabled = true
                  } else if ((element.id === '12-5') && element.disabled) {
                    element.disabled = true
                  } else if (element.id === '12-1') {
                    element.disabled = false
                  }
                })
                if (currentStageAnswers.some(item => item.id === '12-0')) {
                  answerInputs.forEach((element) => {
                    if (element.id === '12-1') {
                      element.disabled = true
                    }
                  })
                }
              } else if (currentStageAnswers.some(item => item.id === '12-4')) {
                if (currentStageAnswers.length === 1) {
                  this.disabledBtnNext = false
                  return
                }
                this.disabledBtnNext = true
                answerInputs.forEach((element) => {
                  if ((element.id === '12-5' && !element.disabled)) {
                    element.disabled = true
                  } else if ((element.id === '12-5') && element.disabled) {
                    element.disabled = true
                    // this.disabledBtnNext = false
                  } else if (element.id === '12-1') {
                    element.disabled = false
                  }
                })
              }
              if (currentStageAnswers.some(item => item.id === '12-5')) {
                answerInputs.forEach((element) => {
                  if ((element.id === '12-0' && !element.getAttribute('disabled')) ||
                    (element.id === '12-1' && !element.getAttribute('disabled')) ||
                    (element.id === '12-2' && !element.getAttribute('disabled')) ||
                    (element.id === '12-3' && !element.getAttribute('disabled')) ||
                    (element.id === '12-4' && !element.getAttribute('disabled'))) {
                    // element.setAttribute('disabled', true)
                    element.disabled = true
                    this.disabledBtnNext = false
                  } else {
                    element.disabled = false
                    this.disabledBtnNext = true
                  }
                })
                this.setPatientData({
                  ...this.getPatientData,
                  [this.getStage]: answer.abbrev
                })
              }
            }

            if (!this.getAnswers[this.getStage]) {
              answerInputs.forEach((element) => {
                element.disabled = false
              })
            }

            this.setAnswers({
              ...this.getAnswers,
              [this.getStage]: currentStageAnswers
            })
          } else {
            const answers = currentStageAnswers.filter(item => item.id !== answer.id)

            if (answers.some(item => item.id === '12-0')) {
              answerInputs.forEach((element) => {
                if ((element.id === '12-1' && !element.disabled) || (element.id === '12-5' && !element.disabled)) {
                  element.setAttribute('disabled', true)
                } else if (element.id === '12-1' || element.id === '12-5') {
                  element.removeAttribute('disabled')
                }
              })
            }
            if (currentStageAnswers.some(item => item.id === '12-0')) {
              answerInputs.forEach((element) => {
                if ((element.id === '12-2')) {
                  element.removeAttribute('disabled')
                  this.disabledBtnNext = false
                }
              })
            }
            if (currentStageAnswers.some(item => item.id === '12-2')) {
              answerInputs.forEach((element) => {
                if ((element.id === '12-0')) {
                  element.removeAttribute('disabled')
                  this.disabledBtnNext = false
                } else if (element.id === '12-5' && !element.disabled) {
                  element.disabled = true
                }
              })
            }
            if (answers.some(item => item.id === '12-1')) {
              answerInputs.forEach((element) => {
                if ((element.id === '12-0' && !element.getAttribute('disabled')) || (element.id === '12-5' && !element.getAttribute('disabled'))) {
                  element.setAttribute('disabled', true)
                } else if (element.id === '12-0' || element.id === '12-5') {
                  element.removeAttribute('disabled')
                }
              })
            } else if (answers.some(item => item.id === '12-1') && answers.length <= 1) {
              this.disabledBtnNext = true
            }
            if (currentStageAnswers.some(item => item.id === '12-2')) {
              answerInputs.forEach((element) => {
                if ((element.id === '12-4') || element.id === '12-3') {
                  element.removeAttribute('disabled')
                  this.disabledBtnNext = true
                }
              })
            }
            if (answers.some(item => item.id === '12-3')) {
              answerInputs.forEach((element) => {
                if (element.id === '12-4' || element.id === '12-5') {
                  this.disabledBtnNext = false
                } else if (element.id === '12-5' && !element.disabled) {
                  element.disabled = true
                } else if (element.id === '12-5' && element.disabled) {
                  element.disabled = false
                } else if (element.id === '12-1' && !element.disabled) {
                  element.disabled = true
                }
              })
            }
            if (answers.some(item => item.id === '12-5')) {
              answerInputs.forEach((element) => {
                if ((element.id === '12-0' && !element.getAttribute('disabled')) ||
                  (element.id === '12-1' && !element.getAttribute('disabled')) ||
                  (element.id === '12-2' && !element.getAttribute('disabled')) ||
                  (element.id === '12-3' && !element.getAttribute('disabled')) ||
                  (element.id === '12-4' && !element.getAttribute('disabled'))) {
                  element.disabled = true
                  this.disabledBtnNext = false
                } else {
                  element.disabled = false
                }
              })
            } else {
              answerInputs.forEach((element) => {
                if ((element.id === '12-0' && element.getAttribute('disabled')) ||
                  (element.id === '12-1' && element.getAttribute('disabled')) ||
                  (element.id === '12-2' && element.getAttribute('disabled')) ||
                  (element.id === '12-3' && element.getAttribute('disabled')) ||
                  (element.id === '12-4' && element.getAttribute('disabled'))) {
                  element.disabled = false
                }
              })
            }

            if (!this.getAnswers[this.getStage]) {
              answerInputs.forEach((element) => {
                element.disabled = false
              })
            }

            this.setAnswers(answers)
          }

          if (currentStageAnswers && this.getCurrentQuestion.id === 12 && currentStageAnswers.some(item => item.id === '12-5')) {
            this.disabledBtnNext = false
            this.setAnswers({
              ...this.getAnswers,
              [this.getStage]: currentStageAnswers.filter(item => item.id === '12-5')
            })
          }
          if (currentStageAnswers && currentStageAnswers.length === 1 && this.getCurrentQuestion.id === 12 && currentStageAnswers.some(item => item.id === '12-2')) {
            this.disabledBtnNext = true
            nextBtn.setAttribute('disabled', 'disabled')
          }
          if (currentStageAnswers && this.getCurrentQuestion.id === 11 && currentStageAnswers.some(item => item.id === '11-6')) {
            answerInputs.forEach((element) => {
              if ((element.id === '11-0' && !element.getAttribute('disabled')) ||
                (element.id === '11-1' && !element.getAttribute('disabled')) ||
                (element.id === '11-2' && !element.getAttribute('disabled')) ||
                (element.id === '11-3' && !element.getAttribute('disabled')) ||
                (element.id === '11-4' && !element.getAttribute('disabled')) ||
                (element.id === '11-5' && !element.getAttribute('disabled'))
              ) {
                element.disabled = true
                this.disabledBtnNext = false
              }
            })
            this.setAnswers({
              ...this.getAnswers,
              [this.getStage]: currentStageAnswers.filter(item => item.id === '11-6')
            })
            if (this.getAnswers[this.getStage][0] && !this.getAnswers[this.getStage][0].id === '11-6') {
              this.setPatientData({
                ...this.getPatientData,
                [this.getStage]: currentStageAnswers.filter(item => item.id === '11-6').abbrev
              })
            }
          }
          if (currentStageAnswers && this.getCurrentQuestion.id === 11 && currentStageAnswers.some(item => item.id !== '11-6')) {
            answerInputs.forEach((element) => {
              if ((element.id === '11-6' && !element.getAttribute('disabled'))) {
                element.disabled = true
                this.disabledBtnNext = false
              }
            })
          }

          if (this.getCurrentQuestion.id === 10 && currentStageAnswers.some(item => item.id === '10-4')) {
            this.setAnswers({
              ...this.getAnswers,
              [this.getStage]: currentStageAnswers.find(item => item.id === '10-4')
            })
          }
          if (this.getCurrentQuestion.id === 8 && currentStageAnswers.some(item => item.id === '8-2')) {
            this.setAnswers({
              ...this.getAnswers,
              [this.getStage]: currentStageAnswers.find(item => item.id === '8-2')
            })
          }
          if (this.getCurrentQuestion.id === 7 && currentStageAnswers.some(item => item.id === '7-3')) {
            this.setAnswers({
              ...this.getAnswers,
              [this.getStage]: currentStageAnswers.find(item => item.id === '7-3')
            })
          }
          break
      }
    },
    handleNext(stage) {
      if (this.getCurrentQuestion.id === 11) {
        if (this.getAnswers[stage][0].next.result === 10) {
          this.goToResult(10)
        }
        this.getAnswers[stage].forEach(element => {
          if (element.id === '11-0' || element.id === '11-1' || element.id === '11-2' || element.id === '11-3' || element.id === '11-4') {
            this.goToResult(8)
          }
          if (element.id === '11-5') {
            this.goToResult(9)
            console.log('a')
          }
          if (element.id === '11-6') {
            this.goToResult(11)
          }
        })
      } else if (this.getCurrentQuestion.id === 12) {
        if (Object.keys(this.getAnswers[stage]).length && this.getAnswers[stage].id === '12-5') {
          this.goToResult(3)

          this.setAnswers(this.getAnswers[stage])
        } else {
          this.getAnswers[stage].forEach(element => {
            if ((element.id === '12-0' && this.getAnswers[stage].some(i => i.id === '12-2')) ||
              (element.id === '12-1' && this.getAnswers[stage].some(i => i.id === '12-2')) ||
              (element.id === '12-0' && this.getAnswers[stage].some(i => i.id === '12-2') && this.getAnswers[stage].some(i => i.id === '12-3')) ||
              (element.id === '12-2' && this.getAnswers[stage].some(i => i.id === '12-3') && this.getAnswers[stage].some(i => i.id === '12-0')) ||
              (element.id === '12-3' && this.getAnswers[stage].some(i => i.id === '12-2') && this.getAnswers[stage].some(i => i.id === '12-0')) ||
              (element.id === '12-0' && this.getAnswers[stage].some(i => i.id === '12-2') && this.getAnswers[stage].some(i => i.id === '12-4')) ||
              (element.id === '12-4' && this.getAnswers[stage].some(i => i.id === '12-2') && this.getAnswers[stage].some(i => i.id === '12-0')) ||
              (element.id === '12-2' && this.getAnswers[stage].some(i => i.id === '12-4') && this.getAnswers[stage].some(i => i.id === '12-0')) ||
              (element.id === '12-1' && this.getAnswers[stage].some(i => i.id === '12-2') && this.getAnswers[stage].some(i => i.id === '12-4')) ||
              (element.id === '12-1' && this.getAnswers[stage].some(i => i.id === '12-2') && this.getAnswers[stage].some(i => i.id === '12-3')) ||
              (element.id === '12-2' && this.getAnswers[stage].some(i => i.id === '12-3') && this.getAnswers[stage].some(i => i.id === '12-1')) ||
              (element.id === '12-3' && this.getAnswers[stage].some(i => i.id === '12-1') && this.getAnswers[stage].some(i => i.id === '12-2')) ||
              (element.id === '12-1' && this.getAnswers[stage].some(i => i.id === '12-2') && this.getAnswers[stage].some(i => i.id === '12-4')) ||
              (element.id === '12-4' && this.getAnswers[stage].some(i => i.id === '12-2') && this.getAnswers[stage].some(i => i.id === '12-1')) ||
              (element.id === '12-2' && this.getAnswers[stage].some(i => i.id === '12-1') && this.getAnswers[stage].some(i => i.id === '12-4'))
            ) {
              this.goToResult(12)
            } else if ((element.id === '12-3' && this.getAnswers[stage].length === 1) ||
              (element.id === '12-0' && this.getAnswers[stage].some(i => i.id === '12-3')) ||
              (element.id === '12-3' && this.getAnswers[stage].some(i => i.id === '12-0')) ||
              (element.id === '12-1' && this.getAnswers[stage].some(i => i.id === '12-3')) ||
              (element.id === '12-3' && this.getAnswers[stage].some(i => i.id === '12-1'))) {
              this.goToResult(13)
            } else if ((element.id === '12-4' && this.getAnswers[stage].length === 1) ||
              (element.id === '12-0' && this.getAnswers[stage].some(i => i.id === '12-4')) ||
              (element.id === '12-4' && this.getAnswers[stage].some(i => i.id === '12-0')) ||
              (element.id === '12-1' && this.getAnswers[stage].some(i => i.id === '12-4')) ||
              (element.id === '12-4' && this.getAnswers[stage].some(i => i.id === '12-1')) ||
              (element.id === '12-3' && this.getAnswers[stage].some(i => i.id === '12-4')) ||
              (element.id === '12-4' && this.getAnswers[stage].some(i => i.id === '12-3')) ||
              (element.id === '12-0' && this.getAnswers[stage].some(i => i.id === '12-3') && this.getAnswers[stage].some(i => i.id === '12-4')) ||
              (element.id === '12-3' && this.getAnswers[stage].some(i => i.id === '12-0') && this.getAnswers[stage].some(i => i.id === '12-4')) ||
              (element.id === '12-4' && this.getAnswers[stage].some(i => i.id === '12-0') && this.getAnswers[stage].some(i => i.id === '12-3')) ||
              (element.id === '12-1' && this.getAnswers[stage].some(i => i.id === '12-3') && this.getAnswers[stage].some(i => i.id === '12-4')) ||
              (element.id === '12-3' && this.getAnswers[stage].some(i => i.id === '12-1') && this.getAnswers[stage].some(i => i.id === '12-4')) ||
              (element.id === '12-4' && this.getAnswers[stage].some(i => i.id === '12-3') && this.getAnswers[stage].some(i => i.id === '12-1')) ||
              (element.id === '12-2' && this.getAnswers[stage].some(i => i.id === '12-3') && this.getAnswers[stage].some(i => i.id === '12-4')) ||
              (element.id === '12-3' && this.getAnswers[stage].some(i => i.id === '12-2') && this.getAnswers[stage].some(i => i.id === '12-4')) ||
              (element.id === '12-4' && this.getAnswers[stage].some(i => i.id === '12-2') && this.getAnswers[stage].some(i => i.id === '12-3')) ||
              (element.id === '12-4' && this.getAnswers[stage].some(i => i.id === '12-5')) ||
              (element.id === '12-5' && this.getAnswers[stage].some(i => i.id === '12-4'))) {
              this.goToResult(14)
            } else if (element.id === '12-5' && this.getAnswers[stage].length === 1) {
              this.goToResult(3)
            }
          })
        }
      } else {
        const next = this.getAnswers[stage].next

        if (this.getCurrentQuestion.id !== 11 && this.getCurrentQuestion.id !== 12) {
          if ('question' in next) {
            this.goToQuestion(next.question)
            this.setStage(this.getStage + 1)
          } else if ('result' in next) {
            this.goToResult(next.result)
          }
        }
      }

      window.scrollTo(0, 0)
    },
    handlePrev() {
      if (this.getStage === 3 && this.getCurrentQuestion.id === 12) {
        this.disabledBtnNext = false
      }
      const prev = this.getPrevQuestions[this.getPrevQuestions.length - 1]
      this.setAnswers({
        ...this.getAnswers,
        [this.getStage]: null
      })

      this.goToQuestion(prev.id)
      this.setStage(this.getStage - 1)
    }
  }
}
</script>

<style lang="scss" scoped>
$fontR: "Montserrat-Regular";
$fontM: "Montserrat-Medium";
$fontB: "Montserrat-Bold";
$fontSB: "Montserrat-SemiBold";

.question {
  &__title {
    font-family: $fontB;
    font-size: 24px;
    line-height: 29px;
  }

  &__subtitle {
    font-family: $fontM;
    font-weight: 500;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  &__answers-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 10px;
    margin-top: 20px;
  }

  &__answers-item {
    height: 130px;
    -webkit-tap-highlight-color: transparent !important;
  }

  &__abbrev-container {
    // padding-top: 55px;
    padding-bottom: 15px;

    &.together {
      padding-bottom: 0;

      &:last-of-type {
        padding-bottom: 15px;
      }
    }

    &:nth-of-type(1) {
      padding-top: 55px;
    }
  }

  .answer {
    position: relative;
    -webkit-tap-highlight-color: transparent !important;

    &__input {
      width: 100%;
      height: 100%;
      opacity: 0;
      border: 1px solid #285EFF;
      border-radius: 50%;
      position: relative;
      cursor: pointer;
      z-index: 2;
      background-color: transparent;
      border: 2px solid rgba(40, 94, 255, 0.2);

      &:checked ~ label {
        background-color: #F1F5FF;
      }

      &:checked ~ label > .decor-container > .decor-round::before {
        background-color: rgba(40, 94, 255, .6);
      }

      &:disabled ~ label {
        opacity: .6 !important;
      }
    }

    &__label {
      position: absolute;
      top: 0;
      left: 0;

      font-family: $fontSB;
      font-weight: 600;
      font-size: 16px;
      line-height: 110%;
      color: #285EFF;

      border-radius: 16px;
      border: 2px solid rgba(40, 94, 255, 0.2);

      height: 100%;
      width: 100%;

      padding-left: 16px;
      padding-right: 40px;

      display: flex;
      align-items: center;

      .decor-container {
        min-width: 36px;
        height: 36px;
        background: transparent;
        top: 5px;
        left: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 14px;

        .decor-round {
          width: 24px;
          height: 24px;
          border: 1px solid #285EFF;
          border-radius: 50%;
          background: transparent;
          position: relative;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          &::before {
            content: '';
            width: 16px;
            height: 16px;
            background: transparent;
            border-radius: 50%;
          }

          &.square {
            border-radius: 4px;

            &::before {
              border-radius: 4px;
            }
          }
        }
      }
    }

    &__text {
      font-family: $fontM;
      font-weight: 500;
      font-size: 12px;
      color: #3C3C3C;
    }
  }

  &.first {
    .question__title {
      color: #285EFF;
      font-size: 32px;
      line-height: 39px;
    }

    .question__answers-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 10px;
    }

    .question__answers-item {
      background: #285EFF;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
      border-radius: 16px;
      font-family: $fontM;
      font-weight: 500;
      font-size: 24px;
      line-height: 110%;
      color: #FFFFFF;
      padding: 40px 30px 30px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      transition-duration: .3s;
      position: relative;
      height: 180px;

      svg {
        // align-self: flex-end;
        position: absolute;
        bottom: 30px;
        right: 30px;
      }

      &:hover {
        background: #1646D6;
      }

      &:active {
        background: #1646D6;
      }
    }

    .question__btn {
      display: none;
    }
  }

  &__controls {
    margin: 30px auto;
    display: flex;
    justify-content: center;
  }

  &__btn {
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent !important;

    &--text {
      color: #FFFFFF;
      font-weight: 500;
      font-size: 16px;
      line-height: 110%;
      font-family: $fontM;
      position: relative;
      z-index: 2;
      transform: translateX(64px);
      transition: all .5s ease-in-out;
    }

    &--decor {
      border: 1px solid #FFFFFF;
      border-radius: 50px;
      width: 30px;
      height: 30px;
      background-color: #FFFFFF;
      position: relative;

      svg {
        top: 6px;
        position: relative;
        left: 2px;
      }

      &.after {
        transform: translateX(90px);
        transition: all .5s ease-in-out;
      }
    }

    &.prev {
      color: #285EFF;
      position: relative;
      font-size: 16px;
      line-height: 18px;
      font-family: $fontM;
      -webkit-tap-highlight-color: transparent !important;

      &::after {
        position: absolute;
        bottom: 16px;
        content: '';
        width: 100%;
        height: 1px;
        left: 0;
        background-color: #285EFF;
      }
    }

    &.next {
      background-color: #FB35A2;
      color: #FFFFFF;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
      border-radius: 50px;
      border-color: transparent;
      width: 178px;
      height: 50px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 14px;
      -webkit-tap-highlight-color: transparent !important;

      &.disabled {
        opacity: .2;
      }

      &:not(.disabled) {
        &:hover {
          background-color: #FB35A2;

          & > .question__btn--decor {
            &.after {
              transform: translateX(-20px);

              @media screen and (max-width: 768px) {
                transform: translateX(90px);
              }
            }
          }
        }

        &:active {
          background-color: #DC1B85;;
        }
      }

    }
  }
}

@media screen and (max-width: 1260px) {
  .question {
    &__answers-list {
      grid-column-gap: 18px;
      grid-template-columns: repeat(1, 0.8fr);
    }

    &.first {
      .question__answers-list {
        display: grid;
        grid-template-columns: repeat(1, 0.8fr);
        grid-column-gap: 30px;
        grid-row-gap: 10px;
      }
    }

    .answer {
      &__label {
        padding-left: 12px;
        padding-right: 12px;

        .decor-container {
          //margin-right: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .question {
    &__answers-list {
      grid-column-gap: 18px;
      grid-template-columns: repeat(1, 1fr);
    }

    &__controls {
      padding-bottom: 84px;
    }

    &.first {
      .question__title {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
      }

      .question__answers-list {
        grid-column-gap: 18px;
        grid-template-columns: repeat(1, 1fr);
      }

      .question__answers-item {
        font-size: 16px;
        line-height: 110%;
        height: 110px;

        svg {
          bottom: 40px;
          right: 14px;
        }
      }

      .answer__title {
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .question {
    &__title {
      font-size: 18px;
      line-height: 22px;
    }

    &__subtitle {
      margin-bottom: 0;
    }

    &__answers-list {
      margin-top: 20px;
    }

    &__answers-item {
      height: 70px;

      &.moreContent {
        height: 94px;
      }

      &.aLot {
        height: 130px;
      }
    }

    &__controls {
      padding-bottom: 24px;
    }

    &__abbrev-container {
      padding-bottom: 54px;

      &.together {
        &:last-child {
          padding-bottom: 54px;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .question {
    .answer {
      &__title {
        font-size: 13px;
        line-height: 13px;
      }

      &__text {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}

</style>

<template>
  <section class="result" :class="{result3: getResult.id === 3}">
    <div
      v-for="(resultText, i) in getResult.textData"
      :key="i"
      class="result__main"
    >
      <div class="result__text" v-html="resultText.text"></div>
      <div class="result__class" :class="resultText.classId">
        <div class="result__class--subtitle">Клас рекомендацій</div>
        <div class="result__class--val">{{ resultText.class }}</div>
      </div>
    </div>
    <div class="result__recom">
      <img src="@/assets/img/icon_doctor.svg" alt="">
      <div class="result__recom--text">
        {{ getResult.recommendations }}
      </div>
    </div>
    <div class="result__controls">
      <div class="result__controls--top">
        <router-link
          v-if="getResult.id === 0 || getResult.id === 1 || getResult.id === 2 || getResult.id === 4 || getResult.id === 6 || getResult.id === 12 || getResult.id === 13 || getResult.id === 14 || getResult.id === 15 || getResult.id === 16 || getResult.id === 17"
          to="/" class="prev-btn"><span onclick="gtag('event', 'click', {'event_category': 'Дізнатись більше про ІКД'})">Дізнатись більше про ІКД</span>
        </router-link>
        <router-link v-if="getResult.id === 3 || getResult.id === 5" to="/risk" class="prev-btn">Дізнатись більше про
          ризик РСС
        </router-link>
        <button class="restart-btn" @click="restart()">
          <div class="restart-btn__text" onclick="gtag('event', 'click', {'event_category': 'Оцінити знов'})">Оцінити знов</div>
          <img src="@/assets/img/icon_restart.svg" alt="">
        </button>
      </div>
      <router-link v-if="getResult.link" to="/calculator" class="main-btn">
        <div class="main-btn__text" onclick="gtag('event', 'click', {'event_category': 'Розрахувати 5-річний ризик розвитку РСС у пацієнта'})">
          Розрахувати 5-річний ризик розвитку РСС у пацієнта
        </div>
        <svg class="main-btn__decor" width="30" height="30" viewBox="0 0 30 30" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <circle cx="15" cy="15" r="15" fill="white"/>
          <path d="M13 8L20 15L13 22" stroke="#FB35A2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </router-link>
    </div>
    <div class="result__bottom">
      <p><strong>ІКД — </strong>імплантація кардіовертера-дефібрилятора</p>
      <p v-if="getResult.id === 12" :style="{marginTop: 0}"><strong>ФВЛШ — </strong>фракція викиду лівого шлуночка</p>
      <p v-if="getResult.id === 0 || getResult.id === 17" :style="{marginTop: 0}"><strong>ШТ — </strong>шлуночкова
        тахікардія</p>
      <p
        v-if="getResult.id === 11 || getResult.id === 5 || getResult.id === 6 || getResult.id === 7 || getResult.id === 8 || getResult.id === 9"
        :style="{marginTop: 0}"><strong>РСС — </strong>раптова серцева смерть</p>
      <template v-if="getResult.id === 9 || getResult.id === 8 || getResult.id === 7 || getResult.id === 11">
        <p>1. Ommen SR et al. 2020 AHA/ACC Guideline for the Diagnosis and Treatment of Patients With Hypertrophic
          Cardiomyopathy: Executive Summary: A Report of the American College of Cardiology/American Heart Association
          Joint Committee on Clinical Practice Guidelines. Circulation. 2020 Dec 22;142(25):e533-e557</p>
      </template>
      <template v-else>
        <p>1. Al-Khatib SM. et al. 2017 AHA/ACC/HRS guideline for management of patients with ventricular arrhythmias
          and the prevention of sudden cardiac death: Executive summary: A Report of the American College of
          Cardiology/American Heart Association Task Force on Clinical Practice Guidelines and the Heart Rhythm Society.
          Heart Rhythm. 2018 Oct;15(10):e190-e252.</p>
        <p v-if="getResult.id === 15">2. Priori SG et al. ESC Scientific Document Group. 2015 ESC Guidelines for the
          management of patients with ventricular arrhythmias and the prevention of sudden cardiac death: The Task Force
          for the Management of Patients with Ventricular Arrhythmias and the Prevention of Sudden Cardiac Death of the
          European Society of Cardiology (ESC). Endorsed by: Association for European Paediatric and Congenital
          Cardiology (AEPC). Eur Heart J. 2015 Nov 1;36(41):2793-2867.</p>
      </template>
    </div>
  </section>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'Result',
  computed: {
    ...mapGetters([
      'getResult'
    ])
  },
  mounted() {
    window.scrollTo(0, 0)
    console.log('a')
    gtag('event', 'result', {"event_category": 'результат тесту: ' + this.getResult.analytic})
  },
  methods: {
    ...mapActions([
      'goRestart'
    ]),
    restart() {
      this.goRestart()
    }
  }
}
</script>

<style lang="scss" scoped>
$fontR: "Montserrat-Regular";
$fontM: "Montserrat-Medium";
$fontB: "Montserrat-Bold";
$fontSB: "Montserrat-SemiBold";

.result {
  // &.result3 {
  //   .result__text {
  //     margin-right: 12px
  //   }
  // }
  &__main {
    background: #FFFFFF;
    border: 2px solid rgba(40, 94, 255, 0.2);
    box-sizing: border-box;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100px;
    margin-bottom: 10px;
  }

  &__text {
    margin-left: 30px;
    margin-right: 20px;

    font-family: $fontM;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

  }

  &__class {
    height: 103%;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    width: 160px;
    min-width: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    position: relative;
    right: -2px;

    &.low {
      background-color: #10CBB5;
      border: 1px solid #10CBB5;
    }

    &.middle {
      background-color: #F7D049;
      border: 1px solid #F7D049;
    }

    &.high {
      background-color: #CB3210;
      border: 1px solid #CB3210;
    }

    &--subtitle {
      font-family: $fontB;
      font-weight: bold;
      font-size: 12px;
      color: #FFFFFF;
    }

    &--val {
      color: #FFFFFF;
      font-family: $fontB;
      font-weight: bold;
      font-size: 48px;
    }
  }

  &__recom {
    background: #285EFF;
    border-radius: 16px;
    padding: 10px 40px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    min-height: 80px;

    img {
      width: 34px;
      min-width: 34px;
      margin-right: 4%;
    }

    &--text {
      color: #FFFFFF;
      font-family: $fontB;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      width: calc(100% - 160px);
    }
  }

  &__controls {
    margin-top: 30px;

    &--top {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__bottom {
    margin-top: 90px;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;

    color: #000000;

    p:nth-of-type(2) {
      margin-top: 12px;
    }

    p:nth-of-type(3) {
      margin-top: 12px;
    }
  }
}

.main-btn {
  background: #285EFF;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 50px;
  width: 335px;
  //min-width: 234px;
  position: relative;
  z-index: 2;
  transition: all .5s ease-in-out;
  -webkit-tap-highlight-color: transparent !important;

  &__text {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    font-family: $fontM;
    text-align: left;
    max-width: 250px;
    position: relative;
    z-index: 2;
    transform: translateX(35px);
    transition: all .5s ease-in-out;
  }

  &__decor {
    border: 1px solid #FFFFFF;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    background-color: #FFFFFF;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    display: block;
    transition: all .5s ease-in-out;
  }

  &:hover {
    .main-btn__text {
      transform: translateX(60px);
      @media screen and (max-width: 540px) {
        transform: translateX(40px) !important;
      }
    }

    .main-btn__decor {
      right: calc(100% - 40px);

      @media screen and (max-width: 540px) {
        right: calc(100% - 29px);
      }
    }
  }

  &:active {
    background-color: #DC1B85;

    svg path {
      stroke: #DC1B85;
    }
  }
}

.restart-btn {
  background-color: #FFFFFF;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  width: 234px;
  height: 50px;
  outline: none;
  border: 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    transform: translateX(66px);
    transition: all .5s ease-in-out;
  }

  &__text {
    color: #3C3C3C;
    font-family: $fontM;
    font-weight: 500;
    font-size: 16px;
    transform: translateX(50px);
    transition: all .5s ease-in-out;
  }

  &:hover {
    .restart-btn__text {
      transform: translateX(74px);
    }

    & > img {
      transform: translateX(-80px);
    }
  }

  &:active {
    background-color: #DFE7FF;
  }
}

.prev-btn {
  color: #285EFF;
  position: relative;
  text-decoration: none;
  margin: 0 10px;

  &::after {
    position: absolute;
    bottom: 0px;
    content: '';
    width: 100%;
    height: 1px;
    left: 0;
    background-color: #285EFF;
  }
}

@media screen and (max-width: 1020px) {
  .result {
    &__text {
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 16px;
      margin-left: 16px;
      margin-right: 16px;
    }

    &__main {
      flex-direction: column;
      padding-top: 16px;
      height: 100%;
    }

    &__class {
      width: 100.5%;
      min-height: 60px;
      border-top-left-radius: 0;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 16px;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 16px;
      left: -1px;

      &--val {
        font-size: 36px;
      }
    }

    &__recom {
      padding: 10px 16px;

      &--text {
        font-size: 15px;
        line-height: 18px;
        text-align: left;
      }
    }

    &__controls {
      margin-top: 40px;
      margin-bottom: 70px;

      &--top {
        flex-direction: column;
      }
    }

    &__bottom {
      margin-bottom: 52px;
    }
  }
  .restart-btn {
    margin-top: 10px;
  }
  .main-btn {
    margin: 10px auto;
  }
}

@media screen and (max-width: 540px) {
  .result {
    &__class {
      width: 101.5%;
      bottom: -2px;
    }

    &__recom--text {
      width: 100%;
    }

    &__bottom {
      margin-bottom: calc(54px + 48px);
    }
  }
}
</style>

<template>
  <section class="user-data" :class="{start: getCurrentQuestion && getCurrentQuestion.id === 0}">
    <div class="user-data__top">
      <h2 class="user-data__title">{{ title }}</h2>
      <img
        class="user-data__btn"
        src="@/assets/img/cross_btn.svg"
        alt=""
        @click="$emit('handleInfo', false)"
      >
    </div>
    <div v-if="Object.keys(userData).length === 0" class="user-data__description">
      <div class="text">Тут будуть відображатися дані з анамнезу пацієнта, які ви оберете</div>
      <img src="@/assets/img/calc/user-data-desc.svg" alt="">
    </div>
    <ul v-else-if="currentPage === 'check' && Object.keys(userData).length !== 0" class="user-data__list">
      <li
        v-for="(userItem, index) in userData"
        :key="index"
      >
        <template v-if="userItem">
          <div v-if="Array.isArray(userItem)" class="user-data__item single">
            <div v-for="(userItemDeep, i) in userItem" :key="i" class="user-data__item--deep">
              <template v-if="userItemDeep.userDataTitle">
                <img class="decor" src="@/assets/img/bullet.svg" alt="">
                <div v-html="userItemDeep.userDataTitle" class="user-data__text"></div>
              </template>
              <template v-else>
                <img class="decor" src="@/assets/img/bullet.svg" alt="">
                <div v-html="userItemDeep.title" class="user-data__text"></div>
              </template>
            </div>
          </div>
          <div v-else class="user-data__item">
            <template v-if="userItem.userDataTitle">
              <img class="decor" src="@/assets/img/bullet.svg" alt="">
              <div class="user-data__text" v-html="userItem.userDataTitle"></div>
            </template>
            <template v-else>
              <img class="decor" src="@/assets/img/bullet.svg" alt="">
              <div class="user-data__text" v-html="userItem.title"></div>
            </template>
          </div>
        </template>
      </li>
      <img class="user-data__img" src="@/assets/img/calc/user-data-desc.svg" alt="">
      <div v-if="getPatientData" class="user-data__links">
        <span class="inside" v-for="(val, i) in getPatientData" :key="i">
          <span v-for="(v, index) in val" :key="index" v-html="v"></span>
        </span>
      </div>
    </ul>
    <ul v-if="currentPage !== 'check' && Object.keys(userData).length !== 0" class="user-data__list in-stream">
      <li
        v-for="(userItem, index) in userData"
        :key="index"
        class="user-data__item"
      >
        <img class="decor" src="@/assets/img/bullet.svg" alt="">
        <div class="user-data__text" v-html="userItem"></div>
      </li>
    </ul>
    <img
      class="user-data__btn mob"
      :class="{calcHideIcon: currentPage === 'calculator'}"
      src="@/assets/img/cross_btn.svg"
      alt=""
      @click="$emit('handleInfo', false)"
    >
  </section>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'UserData',
  props: ['title', 'userData', 'visibleIcon', 'currentPage'],
  computed: {
    ...mapGetters([
      'getPatientData',
      'getCurrentQuestion',
      'getAnswers',
      'getStage'
    ])
  }
}
</script>

<style lang="scss" scoped>
$fontR: "Montserrat-Regular";
$fontM: "Montserrat-Medium";
$fontB: "Montserrat-Bold";
$fontSB: "Montserrat-SemiBold";

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.user-data {
  width: 385px;
  padding: 22px 24px;
  background: #F1F5FF;
  border-radius: 16px;
  height: fit-content;
  // max-height: calc(fit-content + 190px);

  top: 142px;
  right: 75px;
  position: absolute;
  z-index: 10;

  &__description {
    color: rgba($color: #285EFF, $alpha: .5);
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    font-family: $fontM;
    text-align: left;
    display: flex;
    flex-direction: column;

    margin-top: 26px;

    .text {
      width: 80%;
    }

    img {
      margin: 30px auto;
      align-self: center;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__title {
    font-family: $fontB;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #285EFF;
    width: 90%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-top: 28px;
    position: relative;

    .decor {
      max-width: 182px;
    }

    &.in-stream {
      & > .user-data__item {
        flex-direction: row;
      }
    }
  }

  &__item {
    display: flex;
    // flex-direction: column;
    align-items: flex-start;
    font-family: $fontM;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #3C3C3C;
    margin-bottom: 10px;
    padding-right: 8px;

    &--deep {
      display: flex;
      align-items: flex-start;
      margin-top: 8px;
    }

    &.single {
      flex-direction: column;
    }

    img {
      margin-right: 14px;
      margin-top: 2px;
    }
  }

  &__img {
    align-self: center;
    margin-bottom: 20px;
    display: block;
  }

  &__links {
    // margin-top: 55px;
    font-size: 12px;
    line-height: 15px;
    position: relative;
    bottom: 0;
    display: flex;
    flex-direction: column;

    .inside {
      display: flex;
      flex-direction: column;
    }
  }

  &__btn {
    position: absolute;
    top: 24px;
    right: 20px;
    cursor: pointer;

    &.mob {
      display: none;
      position: fixed;
      bottom: 10px;
      z-index: 10;
      max-width: 64px;
      width: 56px;
      height: auto;
    }

    transition: transform .2s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
}

@media screen and (max-width: 1300px) {
  .user-data {
    right: 46px;
    width: 340px;
    padding-left: 12px;
    padding-right: 14px;

    &__item {
      width: 90%;
    }
  }
}

@media screen and (max-width: 1030px) {
  .user-data {
    width: 340px;
    right: 26px;

    &__title {
      font-size: 18px;
      line-height: 22px;
    }

    &__item {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

@media screen and (max-width: 1020px) {
  .user-data {
    bottom: 0px;
    top: auto;
    right: 0;
    width: 100%;
    background: #FFFFFF;
    border-radius: 30px 30px 0 0;
    // height: 100%;
    // max-height: 400px;
    height: fit-content;
    max-height: fit-content;
    padding-left: 20px;
    padding-right: 20px;

    position: fixed;

    box-shadow: 0px -10px 25px rgba(0, 0, 0, 0.25);

    &.start {
      min-height: 180px;

      .user-data__description .text {
        margin-top: 2px;
      }
    }

    &__description {
      font-size: 16px;

      .text {
        width: 100%;
        margin-top: 18px;
      }

      img {
        display: none;
      }
    }

    &__top {
      .user-data__btn {
        display: none;
      }
    }

    &__btn {
      &.mob {
        display: block !important;
        top: auto;

        &.calcHideIcon {
          display: none !important;
        }
      }
    }

    &__list {
      & + .user-data__btn {
        // display: none !important;
        // top: 0;
        // bottom: 50%;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .user-data {
    &__item {
      width: 100%;

      img {
        max-width: 16px;
        margin-right: 8px;
        margin-top: 4px;
      }
    }

    &__title {
      // font-size: 16px;
      line-height: 20px;
    }

    &__links {
      max-width: 80%;
    }
  }
}

@media screen and (max-width: 400px) {
  .user-data {
    &__item {
      width: 85%;
    }
  }
}
</style>
